<template>
  <transition name="modal">
    <div class="modal--mask">
      <div class="modal--wrapper">
        <div class="modal--container">

          <div class="modal--header">
            <slot name="header"></slot>
          </div>

          <div class="modal--body">
            <slot name="body"></slot>
          </div>

          <div class="modal--footer">
            <slot name="footer">
              <button class="modal--default-button" @click="$emit('close')">
                OK
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'modal-promote'
};
</script>

<style lang="scss" scoped>
.modal {
  &--mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
  }

  &--wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  &--container {
    width: 300px;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    font-family: Helvetica, Arial, sans-serif;
  }

  &--header h3 {
    padding: 0;
  }

  &--body {
    padding: 0;
  }

  &--default-button {
    display: block;
    padding: .4em 1em;
    cursor: pointer;

    &:hover {
      background-color: #ecf0f1c5;
    }
  }

  /*
  * The following styles are auto-applied to elements with
  * transition="modal" when their visibility is toggled
  * by Vue.js.
  *
  * You can easily play with the modal transition by editing
  * these styles.
  */

  &--enter-active,
  &--leave-active {
    transition: opacity 0.5s ease;
  }

  &--enter-from,
  &--leave-to {
    opacity: 0;
  }
}
</style>