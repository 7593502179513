<template>
  <div class="nav">
    <ul class="menu-nav">
      <li class="menu-nav-item" :class="{'menu-nav-item-selected': homePage}" @click="goToHome">ALL APPS</li>
      <li class="menu-nav-item" :class="{'menu-nav-item-selected': !homePage}" @click="goToRank">WEEKLY RANKING</li>
    </ul>
    <ul class="order" v-if="homePage">
      <li @click="toggleSortOrder">
        <!-- License: Apache. Made by Richard9394: https://github.com/Richard9394/MingCute -->
        <svg v-if="ascending" class="icon active" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>A-Z_sort_ascending_letters_line</title>
            <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Editor" transform="translate(-240.000000, -96.000000)">
                    <g id="A-Z_sort_ascending_letters_line" transform="translate(240.000000, 96.000000)">
                        <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z" id="MingCute" fill-rule="nonzero"></path>
                        <path d="M10.7586,13 C11.6994,13 12.1893533,14.0920038 11.6139222,14.7923977 L11.5364,14.8778 L7.41424,19 L11,19 C11.5523,19 12,19.4477 12,20 C12,20.51285 11.613973,20.9355092 11.1166239,20.9932725 L11,21 L5.24145,21 C4.30065,21 3.81071515,19.9079962 4.3861123,19.2076023 L4.46363,19.1222 L8.58581,15 L5.00003,15 C4.44774,15 4.00003,14.5523 4.00003,14 C4.00003,13.48715 4.38606566,13.0644908 4.8834079,13.0067275 L5.00003,13 L10.7586,13 Z M17,4 C17.5523,4 18,4.44772 18,5 L18,17.414 L19.1213,16.2927 C19.5119,15.9022 20.145,15.9022 20.5356,16.2927 C20.9261,16.6832 20.9261,17.3164 20.5356,17.7069 L17.7071,20.5354 C17.3166,20.9259 16.6834,20.9259 16.2929,20.5354 L13.4645,17.7069 C13.074,17.3164 13.074,16.6832 13.4645,16.2927 C13.855,15.9022 14.4882,15.9022 14.8787,16.2927 L16,17.414 L16,5 C16,4.44772 16.4477,4 17,4 Z M8.00003,3 C8.674326,3 9.28067533,3.39562382 9.55608491,4.00153967 L9.60994,4.13453 L11.9418,10.6637 C12.1275,11.1838 11.8565,11.756 11.3364,11.9417 C10.85345,12.1142286 10.3254908,11.8927974 10.1038503,11.443863 L10.0583,11.3363 L9.58102,10 L6.41903,10 L5.94177,11.3363 C5.75602,11.8564 5.1838,12.1275 4.66369,11.9417 C4.18073071,11.7692643 3.91252214,11.2635709 4.02540056,10.7757876 L4.05829,10.6637 L6.39012,4.13453 C6.63311,3.45416 7.27757,3 8.00003,3 Z M8.00003,5.57321 L7.13332,8 L8.86674,8 L8.00003,5.57321 Z" id="形状" fill="#09244B"></path>
                    </g>
                </g>
            </g>
        </svg>
        <!-- License: Apache. Made by Richard9394: https://github.com/Richard9394/MingCute -->
        <svg v-else class="icon active" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>Z-A_sort_descending_letters_line</title>
            <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Editor" transform="translate(-816.000000, -96.000000)" fill-rule="nonzero">
                    <g id="Z-A_sort_descending_letters_line" transform="translate(816.000000, 96.000000)">
                        <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z" id="MingCute" fill-rule="nonzero"></path>
                        <path d="M8.00003,12 C8.674326,12 9.28067533,12.3956587 9.55608491,13.0015223 L9.60994,13.1345 L11.9418,19.6637 C12.1275,20.1838 11.8565,20.756 11.3364,20.9417 C10.85345,21.1142286 10.3254908,20.8927974 10.1038503,20.443863 L10.0583,20.3363 L9.58102,19 L6.41903,19 L5.94177,20.3363 C5.75602,20.8564 5.1838,21.1275 4.66369,20.9417 C4.18073071,20.7692643 3.91252214,20.2635709 4.02540056,19.7757876 L4.05829,19.6637 L6.39012,13.1345 C6.63311,12.4542 7.27757,12 8.00003,12 Z M17,4 C17.5523,4 18,4.44772 18,5 L18,17.414 L19.1213,16.2927 C19.5119,15.9022 20.145,15.9022 20.5356,16.2927 C20.9261,16.6832 20.9261,17.3164 20.5356,17.7069 L17.7071,20.5354 C17.3166,20.9259 16.6834,20.9259 16.2929,20.5354 L13.4645,17.7069 C13.074,17.3164 13.074,16.6832 13.4645,16.2927 C13.855,15.9022 14.4882,15.9022 14.8787,16.2927 L16,17.414 L16,5 C16,4.44772 16.4477,4 17,4 Z M8.00003,14.5732 L7.13332,17 L8.86674,17 L8.00003,14.5732 Z M10.7586,3 C11.6994,3 12.1893533,4.09196698 11.6139222,4.79241099 L11.5364,4.87782 L7.41424,9 L11,9 C11.5523,9 12,9.44772 12,10 C12,10.51285 11.613973,10.9355092 11.1166239,10.9932725 L11,11 L5.24145,11 C4.30065,11 3.81071515,9.90803302 4.3861123,9.20758901 L4.46363,9.12218 L8.58581,5 L5.00003,5 C4.44774,5 4.00003,4.55228 4.00003,4 C4.00003,3.48716857 4.38606566,3.06449347 4.8834079,3.0067278 L5.00003,3 L10.7586,3 Z" id="形状" fill="#09244B"></path>
                    </g>
                </g>
            </g>
        </svg>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { ASCENDING } from '@/constants/FiltersNav.js';

export default {
  name: 'TopNav',
  props: {
    isDisableRankMenu: Boolean
  },
  data() {
    return {
      ascending: true
    };
  },
  computed: {
    ...mapState(['homePage']),
  },
  watch: {
    isDisableRankMenu() {
      if (!this.isDisableRankMenu) this.ascending = true
    }
  },
  methods: {
    ...mapMutations(['changeHomePage']),
    goToHome() {
      this.$router.push('/');
      this.changeHomePage(true);
    },
    goToRank() {
      this.$router.push('/ranks');
      this.changeHomePage(false);
    },
    toggleSortOrder() {
      this.ascending = !this.ascending;
      this.$emit("analysis-filter", ASCENDING, '', this.ascending);
      return;
    },
  },
};
</script>

<style scoped lang="scss">
.menu-nav-item-selected {
  background-color: #2e88c5 !important;
}

.nav {
  display: flex;
  background-color: #001f3f;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 0 1em;
  user-select: none;
  font-size: 14px;

  .menu-nav {
    display: flex;
    align-items: center;

    &-item {
      text-align: center;
      padding: .5em 0;
      width: 10em;
      color: #ECF0F1;
      cursor: pointer;
    }
  }

  .order {
    background-color: #ECF0F1;

    .icon {
      cursor: pointer;
      width: 24px;
      height: 24px;
      padding-top: .1em;
    }
  }
}
</style>
