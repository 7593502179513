<template>
  <section class="container">
    <div class="wrapper-item" v-for="(genre, genre_id) in dataByGenre" :key="genre_id">
      <div class="wrapper-item--header">
        <div class="wrapper-item--name" :title="capitalizeFirstLetter(genre_id)">
          <h2>{{capitalizeFirstLetter(genre_id)}}</h2>
        </div>
        <div class="wrapper-item--info"><span>Store</span></div>
        <div class="wrapper-item--info" title="Purchase"><span>Purch.</span></div>
        <div class="wrapper-item--info"><span>Rating</span></div>
        <div class="wrapper-item--info"><span>Reviews</span></div>
        <div class="wrapper-item--info" title="Growth Count"><span>G. Count</span></div>
        <div class="wrapper-item--info" title="Growth Trends"><span>G. Trends</span></div>
      </div>
      <div class="wrapper-item--content" v-for="(app, app_id) in genre.slice(0, 5)" :key="app_id">
        <div class="wrapper-item--name" :title="app.display_name">
          <span class="wrapper-item--name--id">{{(app_id + 1)}}</span>
            <a :href="app.website_page_meta_page_url" target="_blank" class="wrapper-item--name--image" :style="getImageBanner(app)"></a>
            <h3><a :href="app.website_page_meta_page_url" target="_blank">{{formatDisplayName(app.display_name)}}</a></h3>
        </div>
        <div class="wrapper-item--info"><span>{{app.store}}</span></div>
        <div class="wrapper-item--info"><span>{{app.purchase}}</span></div>
        <div class="wrapper-item--info"><span>{{app.rating}}</span></div>
        <div class="wrapper-item--info"><span>{{app.total_ratings_current}}</span></div>
        <div class="wrapper-item--info"><span>
          {{formatGrouth(app.growth_count)}}
        </span></div>
        <div class="wrapper-item--info"><span>
          {{formatGrouth(app.growth_trends).toFixed(2)}}%
        </span></div>
      </div>
      <div class="wrapper-item--more" @click="openModalGenreApps(genre_id)">
        <span>See all</span>
      </div>
    </div>
    <ReturnToTop />
    <modal-see-all-apps
      v-if="showModal"
      @close="closeModalGenreApps()"
    >
      <template v-slot:body>
        
        <div class="wrapper-item wrapper-item--modal">
          <div class="wrapper-item--header">
            <div class="wrapper-item--name" :title="capitalizeFirstLetter(genreIdModal)">
              <h2>{{capitalizeFirstLetter(genreIdModal)}}</h2>
            </div>
            <div class="wrapper-item--info"><span>Store</span></div>
        <div class="wrapper-item--info" title="Purchase"><span>Purch.</span></div>
        <div class="wrapper-item--info"><span>Rating</span></div>
        <div class="wrapper-item--info"><span>Reviews</span></div>
        <div class="wrapper-item--info" title="Growth Count"><span>G. Count</span></div>
        <div class="wrapper-item--info" title="Growth Trends"><span>G. Trends</span></div>
          </div>
          <div class="wrapper-item--content" v-for="(app, app_id) in genreAppsModal" :key="app_id">
            <div class="wrapper-item--name" :title="app.display_name">
              <span class="wrapper-item--name--id--modal">{{(app_id + 1)}}</span>
              <a :href="app.website_page_meta_page_url" target="_blank" class="wrapper-item--name--image" :style="getImageBanner(app)"></a>
              <h3><a :href="app.website_page_meta_page_url" target="_blank">{{formatDisplayName(app.display_name)}}</a></h3>
            </div>
            <div class="wrapper-item--info"><span>{{app.store}}</span></div>
            <div class="wrapper-item--info"><span>{{app.purchase}}</span></div>
            <div class="wrapper-item--info"><span>{{app.rating}}</span></div>
            <div class="wrapper-item--info"><span>{{app.total_ratings_current}}</span></div>
            <div class="wrapper-item--info"><span>
              {{formatGrouth(app.growth_count)}}
            </span></div>
            <div class="wrapper-item--info"><span>
              {{formatGrouth(app.growth_trends).toFixed(2)}}%
            </span></div>
          </div>
        </div>

      </template>
    </modal-see-all-apps>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import ReturnToTop from '@/components/ReturnToTop.vue';
import ModalSeeAllApps from '@/components/ModalSeeAllApps.vue';

export default {
  name: 'RankPage',
  props: {
    siteData: Array,
    rankData: Array,
    sortState: String,
    filterState: String,
    filterOriginState: String,
    cutReviews: [Number, String]
  },
  components: {
    ReturnToTop,
    ModalSeeAllApps
  },
  data() {
    return {
      dataPage: [],
      dataByGenre: [],
      showModal: false,
      genreIdModal: '',
      genreAppsModal: [],
      cutReview: null
    }
  },
  computed: {
    ...mapState(['homePage']),
  },
  created() {
    this.cutReview = this.cutReviews;
    this.dataPage = this.rankData;
    this.topRatedRank();
  },
  watch: {
    cutReviews() {
      this.cutReview = this.cutReviews;
      this.prepareData();
    },
    sortState() {
      if (!this.homePage) this[this.sortState]();
    },
    filterState() {
      if (!this.homePage && this.filterState) this[this.filterState]();
      if (!this.homePage && !this.filterState) this.resetFilters();
    },
    filterOriginState() {
      if (!this.homePage && this.filterOriginState) this[this.filterOriginState]();
      if (!this.homePage && !this.filterOriginState) this.resetFilters();
    }
  },
  // COLOCAR UM LOADING QUANDO FOR ORDENAR
  methods: {
    sortData(target, tiebreaker = 'display_name') {
      this.dataPage = this.dataPage.sort((a,b) => {
        if (b[target] == a[target]) {
          return b[tiebreaker] - a[tiebreaker]
        }
        
        return b[target] - a[target]
      });

      this.prepareData();
      return;
    },
    topReleasesRank() {
      this.sortData('release');
      return;
    },
    topRatedRank() {
      this.sortData('total_ratings_current');
      return;
    },
    topRatingsRank() {
      this.sortData('rating', 'total_ratings_current');
      return;
    },
    growthCountRank() {
      this.sortData('growth_count');
      return;
    },
    growthTrendsRank() {
      this.sortData('growth_trends');
      return;
    },
    justPaidRank(justThis = false) {
      const filteredData = justThis ? this.dataPage : this.rankData;
      this.dataPage = filteredData.filter(item => item.purchase != 'Free' && item.purchase != '-');

      if (justThis) { return; }
      if (this.filterOriginState) this[this.filterOriginState](true);
      
      if (this.sortState == 'topRated') {
        this.topRatedRank();
      }
      else {
        this[this.sortState]();
      }

      this.prepareData();
      
      return;
    },
    justFreeRank(justThis = false) {
      const filteredData = justThis ? this.dataPage : this.rankData;
      this.dataPage = filteredData.filter(item => item.purchase == 'Free');

      if (justThis) { return; }
      if (this.filterOriginState) this[this.filterOriginState](true);
      
      if (this.sortState == 'topRated') {
        this.topRatedRank();
      }
      else {
        this[this.sortState]();
      }

      this.prepareData();
      
      return;
    },
    justAppLabRank(justThis = false) {
      const filteredData = justThis ? this.dataPage : this.rankData;
      this.dataPage = filteredData.filter(item => item.store == 'App Lab');

      if (justThis) { return; }
      if (this.filterState) this[this.filterState](true);
      
      if (this.sortState == 'topRated') {
        this.topRatedRank();
      }
      else {
        this[this.sortState]();
      }

      this.prepareData();
      
      return;
    },
    justQuestStoreRank(justThis = false) {
      const filteredData = justThis ? this.dataPage : this.rankData;
      this.dataPage = filteredData.filter(item => item.store == 'Quest');

      if (justThis) { return; }
      if (this.filterState) this[this.filterState](true);
      
      if (this.sortState == 'topRated') {
        this.topRatedRank();
      }
      else {
        this[this.sortState]();
      }

      this.prepareData();
      
      return;
    },
    resetFilters() {
      this.dataPage = this.rankData;
      
      if (this.filterState) this[this.filterState](true);
      if (this.filterOriginState) this[this.filterOriginState](true);
      // if (!this.filterState && !this.filterOriginState) this[this.sortState]();
      if (!this.filterState && !this.filterOriginState) {
        if (this.sortState == 'topRated') {
          this.topRatedRank();
        }
        else {
          this[this.sortState]();
        }
      }

      this.prepareData();
      return;
    },
    prepareData() {
      const dataByGenre = {};

      this.dataPage.forEach(item => {
          const { genre_names } = item;

          if (!dataByGenre[genre_names]) {
            dataByGenre[genre_names] = [];
          }

          if (item.total_ratings_current > Number(this.cutReview)) {
            dataByGenre[genre_names].push(item);
          }
      });

      const sortedKeys = Object.keys(dataByGenre).sort();
      const sortedDataByGenre = {};
      
      sortedKeys.forEach(key => {
        if (dataByGenre[key].length > 0) sortedDataByGenre[key] = dataByGenre[key];
      });

      this.dataByGenre = sortedDataByGenre;
    },
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    getImageBanner(item) {
      return {
        'background-image': `url(data/images/${item.display_id}.webp)`
      };
    },
    formatDisplayName(displayName, length = 23) {
      return displayName.length > length ? `${displayName.slice(0, length)} ...` : displayName
    },
    openModalGenreApps(genre) {
      this.genreIdModal = genre;
      this.genreAppsModal = this.dataByGenre[genre];
      this.showModal = true;

      return;
    },
    closeModalGenreApps() {
      this.genreIdModal = '';
      this.genreAppsModal = [];
      this.showModal = false;

      return;
    },
    formatGrouth(growth) {
      return (Number(growth) < 0) ? 0 : Number(growth);
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  background: #ECF0F1;
  padding-bottom: 2rem;
}

.wrapper-item {
  width: 575px;
  padding: 1em;
  margin: 1em 0 1em 0;
  font-size: 12px;
  border: solid #FFA500 1px;
  background: #fff;

  &--modal {
    width: 100%;
    margin-bottom: 0;
  }

  &--header {
    font-weight: bold;
    margin-bottom: .5em;

    h2 {
      font-size: 14px;
    }
  }

  &--content {
    padding: .1em 0;

    h3 {
      font-size: 12px;
    }
  }

  &--more {
    width: 100%;
    text-align: right;
    padding-top: .5em;

    & span {
      cursor: pointer;
    }
  }

  &--header,
  &--content {
    display: flex;
    align-items: center;
  }

  &--info {
    flex: 1;
  }

  &--name {
    flex: 4;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;

    a {
      color: #000;
    }

    &--id {
      width: .8em;

      &--modal {
        width: 2em;
      }
    }

    &--image {
      margin: 0 .5em;
      width: 30px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
}
</style>