<template>
  <div class="sub-filter">
    
    <div class="menu-burger" :class="{'menu-burger--active': showMenu}" @click="toogleMenuBurger">
      <div class="menu-burger__patty"></div>
    </div>

    <div class="sub-filter-wrapper" :class="{'sub-filter-wrapper--class': showMenu}">
      <div v-if="!homePage" class="sub-filter-menu sub-filter-menu-select">
        <span class="sub-filter-title">How many reviews?</span>
        <select v-model="cutReviews">
          <option disabled value="">Over ...</option>
          <option>0</option>
          <option>50</option>
          <option>100</option>
          <option>500</option>
          <option>1000</option>
        </select>
      </div>
      
      <div class="sub-filter-menu">
        <span class="sub-filter-title">Filter by:</span>
        <ul class="sub-filter-menu">
          <li class="sub-filter-item">
            <input type="checkbox" id="justQuestStore" name="justQuestStore" v-model="isJustQuestStore"/>
            <label for="justQuestStore">Quest Store</label>
          </li>
          <li class="sub-filter-item">
            <input type="checkbox" id="justAppLab" name="justAppLab" v-model="isJustAppLab"/>
            <label for="justAppLab">App Lab</label>
          </li>
          <li class="sub-filter-item">
            <input type="checkbox" id="justPaid" name="justPaid" v-model="isJustPaid"/>
            <label for="justPaid">Paid</label>
          </li>
          <li class="sub-filter-item">
            <input type="checkbox" id="justFree" name="justFree" v-model="isJustFree"/>
            <label for="justFree">Free</label>
          </li>
        </ul>
      </div>

      <div class="sub-filter-menu">
        <span class="sub-filter-title">Rank by:</span>
        <ul class="sub-filter-menu">

          <li class="sub-filter-item">
            <input type="radio" id="topReview" name="rankBy" value="byTopReview" v-model="rankBy"/>
            <label for="topReview">Top Review</label>
          </li>
          <li class="sub-filter-item">
            <input type="radio" id="topStars" name="rankBy" value="byTopStars" v-model="rankBy"/>
            <label for="topStars">Top Stars</label>
          </li>

          <li v-if="!homePage" class="sub-filter-item">
            <input type="radio" id="growthCount" name="rankBy" value="byGrowthCount" v-model="rankBy"/>
            <label for="growthCount" title="Growth Count">G. Count</label>
          </li>
          <li v-if="!homePage" class="sub-filter-item">
            <input type="radio" id="growthTrends" name="rankBy" value="byGrowthTrends" v-model="rankBy"/>
            <label for="growthTrends" title="Growth Trends">G. Trends</label>
          </li>

          <li class="sub-filter-item">
            <input type="radio" id="releaseDate" name="rankBy" value="byReleaseDate" v-model="rankBy"/>
            <label for="releaseDate">Release Date</label>
          </li>
        </ul>
      </div>      
    </div>

    <div class="sub-filter-info">
      <span class="sub-filter-info-previous">{{previousUpdate}}</span>
      <span>{{weekUpdate}}</span>
      <span @click="showModal = true">Access full history!</span>
    </div>

    <modal-promote
      v-if="showModal"
      @close="showModal = false"
    >
      <template v-slot:header>
        <h3 class="modal--header">Access full history</h3>
      </template>
      <template v-slot:body>
        <article>
          <p class="modal--body">Are you interested in access full history apps and games here?</p>
          <p class="modal--body">Please get in touch with us at <a href="mailto:contact@vresearchlab.com?subject=Interested in access full history (applabquest)">contact@vresearchlab.com</a> stating your interest.</p>
        </article>
      </template>
    </modal-promote>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  PAID,
  FREE,
  APP_LAB,
  QUEST_STORE,
  RESET,
  TOP_RATED,
  TOP_RATINGS,
  TOP_RELEASES,
  GROWTH_COUNT,
  GROWTH_TRENDS
} from '@/constants/FiltersNav.js';
import ModalPromote from './ModalPromote.vue';

export default {
  name: 'SubNavFilter',
  props: {
    weekUpdate: String,
    previousUpdate: String,
    isDisableRankMenu: Boolean
  },
  components: {
    ModalPromote
  },
  computed: {
    ...mapState(['homePage']),
  },
  data() {
    return {
      isJustPaid: false,
      isJustFree: false,
      isJustAppLab: false,
      isJustQuestStore: false,
      rankBy: 'byTopReview',
      showModal: false,
      showMenu: false,
      cutReviews: 0
    };
  },
  watch: {
    isJustPaid() {
      this.verifyOrderEvent();
    },
    isJustFree() {
      this.verifyOrderEvent();
    },
    isJustAppLab() {
      this.verifyOrderEvent();
    },
    isJustQuestStore() {
      this.verifyOrderEvent();
    },
    homePage() {
      this.clearFilters();
      this.rankBy = 'byTopReview';
      // if (this.homePage) this.rankBy = 'byTopReview';
      // if (!this.homePage) this.rankBy = 'byTopStars';
    },
    rankBy() {
      if (this.rankBy) this.verifyFilterEvent();
    },
    isDisableRankMenu() {
      if (this.isDisableRankMenu) this.rankBy = null;
    },
    cutReviews() {
      this.$emit("cut-reviews", this.cutReviews);
    }
  },
  methods: {
    clearFilters() {
      this.isJustQuestStore = false;
      this.isJustAppLab = false;
      this.isJustPaid = false;
      this.isJustFree = false;
      this.$emit("analysis-filter", RESET);

      return;
    },
    verifyOrderEvent() {
      if (
        (this.isJustPaid && !this.isJustFree && !this.isJustAppLab && !this.isJustQuestStore) ||
        (this.isJustAppLab && this.isJustQuestStore && this.isJustPaid && !this.isJustFree)
      ) {
        this.$emit("analysis-filter", RESET);
        this.$emit("analysis-filter", PAID);
        return;
      }

      if (
        (!this.isJustPaid && this.isJustFree && !this.isJustAppLab && !this.isJustQuestStore) ||
        (this.isJustAppLab && this.isJustQuestStore && !this.isJustPaid && this.isJustFree)
      ) {
        this.$emit("analysis-filter", RESET);
        this.$emit("analysis-filter", FREE);
        return;
      }

      if (
        (this.isJustAppLab && !this.isJustQuestStore && !this.isJustPaid && !this.isJustFree) ||
        (this.isJustPaid && this.isJustFree && this.isJustAppLab && !this.isJustQuestStore)
      ) {
        this.$emit("analysis-filter", RESET);
        this.$emit("analysis-filter", APP_LAB);
        return;
      }

      if (
        (!this.isJustAppLab && this.isJustQuestStore && !this.isJustPaid && !this.isJustFree) ||
        (this.isJustPaid && this.isJustFree && !this.isJustAppLab && this.isJustQuestStore)
      ) {
        this.$emit("analysis-filter", RESET);
        this.$emit("analysis-filter", QUEST_STORE);
        return;
      }

      if (this.isJustAppLab && !this.isJustQuestStore && this.isJustPaid && !this.isJustFree) {
        this.$emit("analysis-filter", PAID);
        this.$emit("analysis-filter", APP_LAB, '', true, true);
        return;
      }

      if (this.isJustAppLab && !this.isJustQuestStore && !this.isJustPaid && this.isJustFree) {
        this.$emit("analysis-filter", FREE);
        this.$emit("analysis-filter", APP_LAB, '', true, true);
        return;
      }

      if (!this.isJustAppLab && this.isJustQuestStore && this.isJustPaid && !this.isJustFree) {
        this.$emit("analysis-filter", PAID);
        this.$emit("analysis-filter", QUEST_STORE, '', true, true);
        return;
      }

      if (!this.isJustAppLab && this.isJustQuestStore && !this.isJustPaid && this.isJustFree) {
        this.$emit("analysis-filter", FREE);
        this.$emit("analysis-filter", QUEST_STORE, '', true, true);
        return;
      }

      this.$emit("analysis-filter", RESET);
    },
    verifyFilterEvent() {
      if (this.rankBy === 'byTopReview') return this.$emit("analysis-filter", TOP_RATED);
      if (this.rankBy === 'byTopStars') return this.$emit("analysis-filter", TOP_RATINGS);
      if (this.rankBy === 'byReleaseDate') return this.$emit("analysis-filter", TOP_RELEASES);
      if (this.rankBy === 'byGrowthCount') return this.$emit("analysis-filter", GROWTH_COUNT);
      if (this.rankBy === 'byGrowthTrends') return this.$emit("analysis-filter", GROWTH_TRENDS);
    },
    toogleMenuBurger() {
      this.showMenu = !this.showMenu;
    }
  },
};
</script>

<style scoped lang="scss">
$transitionSeconds: 0.2s;

.sub-filter {
  position: relative;
  background: #fff;
  font-size: 14px;
  padding: 0 1em;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-info {
    height: 100%;
    padding: .2em 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;

    &-previous {
      color: #aaa;
    }

    & span {
      padding: 0.1em 0;

      &:nth-child(3) {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  &-title {
    width: 5em;
  }

  &-menu {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .sub-filter-item {
      display: flex;
      align-items: center;
      height: 2em;
      width: 8em;

      input[type=checkbox],
      input[type=radio] {
        height: 14px;
        width: 14px;
        margin: 0;
        margin-right: .5em;
        cursor: pointer;
      }

      & label {
        cursor: pointer;
      }
    }

    &-select {
      margin-top: .5em;

      span,
      select {
        width: 10em;
      }

      select {
        padding: .2em;
        background: #fff;
        border: #eee solid 1px;
        border-radius: 3px;
        outline: none;
      }
    }
  }

  .modal--header,
  .modal--body {
    padding-bottom: 1em;
  }
}

.menu-burger {
  display: none;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  cursor: pointer;
  transition: all $transitionSeconds linear;
  
  &__patty {
    position: relative;
    height: 2px;
    width: 40px;
    background: #001F3F;
    
    &:before {
      content: "";
      position: absolute;
      top: -10px;
      left: 0;
      height: 2px;
      width: 100%;
      background: #001F3F;
    }
    
    &:after {
      content: "";
      position: absolute;
      top: 10px;
      left: 0;
      height: 2px;
      width: 100%;
      background: #001F3F;
    }
  }

  &__patty,
  &__patty:before,
  &__patty:after {
    will-change: transform;
    transition: all $transitionSeconds linear;
  }

  &--active {
    .menu-burger__patty {
      transform: rotate(360deg);
      
      &:before {
        transform: rotate(-45deg) translate(-7px,-7px) scaleX(0.7);
      }
      
      &:after {
        transform: rotate(45deg) translate(-7px,7px) scaleX(0.7);
      }
    }
  }
}

@media (max-width: 700px) {
  .sub-filter {
    padding: .5em 1em;
  }
  
  .menu-burger {
    display: flex;
  }
  
  .sub-filter-wrapper {
    left: -1000px;
    background: #fff;
    position: absolute;
    top: 75px;
    z-index: 99;
    padding: 0 1em;
    height: 28em;
    min-width: 20em;
    transition: left $transitionSeconds linear;

    &--class {
      left: 0 !important;

    }

    // &:nth-child(1)::after {
    //   content: "-";
    // }
    
    .sub-filter-menu {
      align-items: flex-start;
      flex-direction: column;

      ul {
        padding-left: 1em;
      }
    }
  }
}
</style>
