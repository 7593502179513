<template>
  <footer class="fixed-footer">
    <p>AppLabQuest.com is not affiliated with Oculus or Facebook Technologies, LLC.</p>
  </footer>
</template>

<script>
export default {
  name: 'FooterFixed'
};
</script>

<style scoped lang="scss">
.fixed-footer {
    width: 100%;
    position: fixed;
    background-color: #ECF0F1;
    z-index: 99;
    bottom: 0;

    p {
        text-align: center;
        padding: .6em;
    }
}
</style>
