<template>
  <main>
    <DefaultHeader @analysis-filter="analysisFilter" />
    
    <TopNav
      :isDisableRankMenu="isDisableRankMenu"
      @analysis-filter="analysisFilter"
    />

    <SubNavFilter
      :weekUpdate="weekUpdate"
      :previousUpdate="previousUpdate"
      :isDisableRankMenu="isDisableRankMenu"
      @analysis-filter="analysisFilter"
      @cut-reviews="getCutReviews"
    />

    <RouterView
      :siteData="filteredData"
      :rankData="rankData"
      :sortState="sortState"
      :filterState="filterState"
      :filterOriginState="filterOriginState"
      :cutReviews="cutReviews"
      @loadMore="loadMoreItems"
    />

    <FooterFixed />
  </main>
</template>

<script>
import { mapState } from 'vuex';
import DefaultHeader from "@/components/DefaultHeader.vue";
import TopNav from "@/components/TopNav.vue";
import SubNavFilter from "@/components/SubNavFilter.vue";
import FooterFixed from "@/components/FooterFixed.vue";
import {
  TOP_RATED,
  TOP_RATINGS,
  TOP_RELEASES,
  ASCENDING,
  GROWTH_COUNT,
  GROWTH_TRENDS,
  QUERY,
  PAID,
  FREE,
  APP_LAB,
  QUEST_STORE,
  RESET
} from '@/constants/FiltersNav.js';

export default {
  components: {
    DefaultHeader,
    TopNav,
    SubNavFilter,
    FooterFixed
  },
  data () {
    return {
      baseData: null,
      siteData: [],
      searchData: "",
      itemsPerPage: 20,
      filteredData: [],
      sortState: null,
      filterState: null,
      filterOriginState: null,
      queryFilter: '',
      ascendingSort: true,
      isDisableRankMenu: false,
      weekUpdate: '',
      previousUpdate: '',
      sponsored: [],
      rankData: [],
      cutReviews: 0
    }
  },
  created() {
    this.loadSitedata();
    this.loadRankdata();
  },
  computed: {
    ...mapState(['homePage']),
  },
  methods: {
    async loadSitedata() {
      try {
        const response = await fetch('/data/sitedata/sitedata.json');
        
        if (!response.ok) {
          throw new Error('Error read site data!');
        }

        this.baseData = await response.json();
        this.weekUpdate = `Week: ${this.baseData[0].datetime_scraped.substring(0, 10)}`;
        this.siteData = this.baseData;

        this.topRated();
        this.selectSponsored();
        this.upSponsoreds();
        this.loadMoreItems();

      } catch (error) {
        console.error('Error:', error.message);
      }
    },
    async loadRankdata() {
      try {
        const response = await fetch('/data/sitedata/rankdata.json');
        
        if (!response.ok) {
          throw new Error('Error read site data!');
        }

        this.rankData = await response.json();
        this.previousUpdate = this.rankData[0].previous_date;

      } catch (error) {
        console.error('Error:', error.message);
      }
    },
    sliceItems(data, base) {
      const startIndex = data.length;
      const endIndex = startIndex + this.itemsPerPage;

      return data.concat(base.slice(startIndex, endIndex));
    },
    loadMoreItems() {
      this.filteredData = this.sliceItems(this.filteredData, this.siteData)

      return;
    },
    prepareData(concat = false) {

      if (this.sortState == 'topRatedRank') this.sortState = 'topRated';
      if (this.sortState == 'topRatingsRank') this.sortState = 'topRatings';
      if (this.sortState == 'topReleasesRank') this.sortState = 'topReleases';

      if (this.queryFilter != '') {
        this.updateSearch();
      }
      else if (!concat) {
        this.siteData = this.baseData;
      }

      if (this.filterState == 'updateSearch') {
        this[this.filterState](this.queryFilter);
      }
      else if (this.filterState) {
        this[this.filterState]();
      }

      if (this.filterOriginState) {
        this[this.filterOriginState]();
      }

      if (this.sortState == 'ascendingName') {
        this[this.sortState](this.ascendingSort);
      }
      else {
        this[this.sortState]();
      }

      this.upSponsoreds();
      this.filteredData = this.sliceItems([], this.siteData);
    },
    updateSearch() {
      this.siteData = this.baseData.filter(item =>
        (item.display_name||'').toLowerCase().includes(this.queryFilter.toLowerCase())
      );
      
      return;
    },
    ascendingName() {
      this.siteData = this.siteData.slice().sort((a, b) => {
        const nameA = a.display_name.toLowerCase();
        const nameB = b.display_name.toLowerCase();

        if (this.ascendingSort) {
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        }
        else {
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }
        }
        
        return 0;
      });

      this.sortState = 'ascendingName';
      return;
    },
    analysisFilter(type, query = '', ascending = true, concat = false) {

      // sorting
      if (type === TOP_RATED) {
        this.sortState = this.homePage ? 'topRated' : 'topRatedRank';
        this.disableRank(false);
      }

      // no futuro, migrar para dentro das pages como fiz no pageRank e passar a penas o state, posso usar o mesmo nome nos métodos
      else if (type === TOP_RATINGS) {
        this.sortState = this.homePage ? 'topRatings' : 'topRatingsRank';
        this.disableRank(false);
      }

      else if (type === TOP_RELEASES) {
        this.sortState = this.homePage ? 'topReleases' : 'topReleasesRank';
        this.disableRank(false);
      }

      else if (type === ASCENDING) {
        this.ascendingSort = ascending;
        this.sortState = 'ascendingName';
        this.disableRank(true);
      }

      else if (type === GROWTH_COUNT) {
        this.sortState = 'growthCountRank';
        this.disableRank(false);
      }

      else if (type === GROWTH_TRENDS) {
        this.sortState = 'growthTrendsRank';
        this.disableRank(false);
      }

      // filters
      else if (type === QUERY) {
        this.queryFilter = query;
        this.filterState = 'updateSearch';
      }

      else if (type === PAID) {
        this.filterState = this.homePage ? 'justPaid' : 'justPaidRank';
      }

      else if (type === FREE) {
        this.filterState = this.homePage ? 'justFree' : 'justFreeRank';
      }

      else if (type === APP_LAB) {
        this.filterOriginState = this.homePage ? 'justAppLab' : 'justAppLabRank';
      }

      else if (type === QUEST_STORE) {
        this.filterOriginState = this.homePage ? 'justQuestStore' : 'justQuestStoreRank';
      }

      else if (type === RESET) {
        this.filterState = null;
        this.filterOriginState = null;
      }

      if (this.homePage) this.prepareData(concat);
      return;
    },
    getCutReviews(cutReviews) {
      this.cutReviews = cutReviews;
    },
    topRated() {
      this.siteData = this.siteData.sort((a,b) => b.total_ratings - a.total_ratings);
      this.sortState = 'topRated';
      return;
    },
    topRatings() {
      this.siteData = this.siteData.sort((a,b) => {
        if (b.rating == a.rating) {
          return b.total_ratings - a.total_ratings
        }
        
        return b.rating - a.rating
      });

      this.sortState = 'topRatings';
      return;
    },
    topReleases() {
      this.siteData = this.siteData.sort((a,b) => b.release - a.release);
      this.sortState = 'topReleases';
      return;
    },
    justPaid() {
      this.siteData = this.siteData.filter(
        item => Number(item.current_offer_price_offset_amount) != 0 && !isNaN(Number(item.current_offer_price_offset_amount))
      );
      return;
    },
    justFree() {
      this.siteData = this.siteData.filter(item => item.current_offer_price_offset_amount == '0');
      return;
    },
    justAppLab() {
      this.siteData = this.siteData.filter(item => item.is_concept);
      return;
    },
    justQuestStore() {
      this.siteData = this.siteData.filter(item => !item.is_concept);
      return;
    },
    upSponsoreds() {
      this.siteData = this.siteData.filter(item => item.display_id !== this.sponsored.display_id);
      this.siteData = [this.sponsored].concat(this.siteData);

      return;
    },
    selectSponsored() {
      let sponsoreds = this.baseData.filter(item => item.sponsored);
      let sponsoreds_index = Math.floor(Math.random() * (sponsoreds.length));
      this.sponsored = sponsoreds[sponsoreds_index];
    },
    disableRank(isActive) {
      this.isDisableRankMenu = isActive;
    }
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
  border:	0;
  vertical-align: baseline;
  list-style: none;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Jura', serif;
  -webkit-user-drag: none;
}

#app {
  position: relative;
}
</style>
