<template>
  <article class="content-principal" :class="{'content-principal-loader': loaderPage}">
    <span class="loader" :style="{'display': loaderPage ? 'inherit' : 'none'}"></span>
    <section
      v-for="(item, index) in siteData"
      :key="index"
      class="content-principal-item"
      :style="getImageBanner(item)"
    >
      <div v-if="item.sponsored" class="content-principal-item-sponsored-tag" title="Promoted app">PROMOTED</div>
      <footer class="content-principal-item-footer">
        <div class="content-principal-item-footer-content">
          <h2 :title="item.display_name">{{formatDisplayName(item.display_name)}}</h2>
          <div class="content-principal-item-footer-item">
            <div class="content-principal-item-footer-item-info">
              <img src="../assets/star.png">
              
              <span v-if="item.rating">{{item.rating.toFixed(2)}}</span>
              <span v-else>0</span>
              
              <span style="padding-left: .2em;">({{item.total_ratings}})</span>
              
              <span v-if="item.current_offer_price_offset_amount != '0'" style="padding-left: 1em;">
                {{item.current_offer_price_formatted}}
              </span>
              <span v-else style="padding-left: 1em;">FREE</span>
            </div>
          </div>
        </div>
        <div class="content-principal-item-footer-link">
          <a
            v-if="item.website_page_meta_page_url"
            :href="item.website_page_meta_page_url"
            target="__blank"
          ><img src="../assets/icon-download.png"></a>
        </div>
      </footer>
    </section>
  </article>
</template>

<script>
export default {
  name: 'game-list',
  props: {
    siteData: Object
  },
  data() {
    return {
      loaderPage: true
    }
  },
  created() {
    this.hiddenLoaderPage();
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleScroll);
    this.$el.addEventListener('touchmove', this.handleTouchMove);
    this.hiddenLoaderPage();
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleScroll);
    this.$el.removeEventListener('touchmove', this.handleTouchMove);
  },
  watch: {
    siteData() {
      this.hiddenLoaderPage();
    }
  },
  methods: {
    hiddenLoaderPage() {
      if (this.siteData.length > 0) this.loaderPage = false;
    },
    handleScroll() {
      const body = document.body;
      const html = document.documentElement;
      const height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
      
      var maxScrollTop = height - window.innerHeight;
      var scrollTop = document.documentElement.scrollTop || document.scrollingElement.scrollTop;
      var calc = maxScrollTop - scrollTop;

      if (calc < 50) {
        this.$emit('loadMore');
      }
    },
    getImageBanner(item) {
      return {
        'background-image': `url(data/images/${item.display_id}.webp)`
      };
    },
    formatDisplayName(displayName) {
      return displayName.length > 26 ? `${displayName.slice(0, 26)} ...` : displayName
    }
  },
};
</script>

<style scoped lang="scss">
.loader {
  color: #dbdbdb;
  font-size: 45px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  transform: translateZ(0);
  animation: mltShdSpin 1.7s infinite ease, round 1.7s infinite ease;
}

@keyframes mltShdSpin {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em,
    0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
    0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 
    0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 
    0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, 
    -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, 
    -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
     -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, 
     -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
     -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, 
     -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 
    0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes round {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}

.content-principal-loader {
  height: 82vh !important;
  align-items: center !important;
}

.content-principal {
  width: 100%;
  height: 100%;
  background-color: #ECF0F1;
  overflow: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1em;

  .content-principal-item {
    width: 350px;
    height: 250px;
    margin: 0.6em;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    border: solid #FFA500 1px;

    .content-principal-item-sponsored-tag {
      background-color: #FFC107;
      opacity: .6;
      padding: .1em 1em;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      right: 0;
      font-size: 12px;
      user-select: none;
    }

    .content-principal-item-footer {
      width: 100%;
      padding: .4em .6em;
      position: absolute;
      bottom: 0;
      background-color: rgb(0 0 0 / 85%);
      color: #ECF0F1;
      display: flex;
      flex-wrap: wrap;

      .content-principal-item-footer-content {
        width: 90%;

        h2 {
          font-size: 18px;
        }

        .content-principal-item-footer-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: bold;
          margin-top: .2em;
          font-size: 14px;

          .content-principal-item-footer-item-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          img {
            height: 15px;
            padding-top: .1em;
            padding-right: .2em;
          }

          a {
            color: inherit;
            
            &:hover {
              color: #001F3F;
            }
          }
        }
      }

      .content-principal-item-footer-link {
        width: 10%;

        a {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 70%;
            padding: 4px;
            background-color: #ECF0F1;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .content-principal {
    .content-principal-item {
      width: 275px;
      height: 150px;
    }
  }
}
</style>
