export const TOP_RATED = 'toprated';
export const TOP_RATINGS = 'topratings';
export const TOP_RELEASES = 'topreleases';
export const ASCENDING = 'ascending';
export const GROWTH_COUNT = 'growthcount';
export const GROWTH_TRENDS = 'growthtrends';

export const QUERY = 'query';
export const PAID = 'paid';
export const FREE = 'free';
export const APP_LAB = 'applab';
export const QUEST_STORE = 'queststore'

export const RESET = 'reset'