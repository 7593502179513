<template>
  <div id="toTop" class="top-icon" @click="returnTop();">
    <img src="./../assets/top.png" alt="return to top icon">
  </div>
</template>

<script>
export default {
  name: 'returnToTop',
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleScroll);
    this.$el.addEventListener('touchmove', this.handleTouchMove);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleScroll);
    this.$el.removeEventListener('touchmove', this.handleTouchMove);
  },
  methods: {
    handleScroll() {
      const scrollTop = document.documentElement.scrollTop || document.scrollingElement.scrollTop;
      const heightscreen = window.screen.height

      let scroll_to_top = document.getElementById('toTop');
      
      if (scrollTop > (heightscreen / 2)) {
        scroll_to_top.style.display = 'flex';
      }
      else {
        scroll_to_top.style.display = 'none';
      }
    },
    returnTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });

      return;
    }
  }
}
</script>

<style scoped lang="scss">
.top-icon {
  background-color: #ffffffab;
  position: fixed;
  padding: 1em;
  bottom: 3em;
  right: 1em;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 99;
}
</style>
