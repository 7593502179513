<template>
  <header class="header">
    <h1>APPLABQUEST</h1>
    <div class="header--links">
      <div class="header--links--wrapper">
        <span class="header--links--wrapper--item" @click="openPromoteModal()">Promote</span>
        <span>|</span>
        <span class="header--links--wrapper--item" @click="openAddModal()">Add your Game</span>
      </div>
      <div class="header--links--search">
        <input type="text" placeholder="search..." v-if="homePage" v-model="searchQuery" @input="updateSearch" />
      </div>
    </div>

    <modal-promote
      v-if="showModal"
      @close="showModal = false"
    >
      <template v-slot:header>
        <h3 v-if="promoteApp" class="modal--header">Promote my App</h3>
        <h3 v-else class="modal--header">Add my App</h3>
      </template>
      <template v-slot:body>
        <article>
          <p class="modal--body">Are you interested in <span v-if="promoteApp">promoting</span><span v-else>adding</span> your apps and games here?</p>
          <p class="modal--body">Please get in touch with us at <a href="mailto:contact@vresearchlab.com?subject=Interested in promoting app (applabquest)">contact@vresearchlab.com</a> stating your interest.</p>
        </article>
      </template>
    </modal-promote>
  </header>
</template>

<script>
import { mapState } from 'vuex';
import ModalPromote from './ModalPromote.vue';
import { QUERY } from '@/constants/FiltersNav.js';

export default {
  name: 'default-header',
  components: {
    ModalPromote
  },
  data() {
    return {
      searchQuery: "",
      showModal: false,
      promoteApp: false
    };
  },
  computed: {
    ...mapState(['homePage']),
  },
  methods: {
    updateSearch() {
      this.$emit("analysis-filter", QUERY, this.searchQuery);
    },
    openPromoteModal() {
      this.showModal = true;
      this.promoteApp = true;
    },
    openAddModal() {
      this.showModal = true;
      this.promoteApp = false;
    }
  },
};
</script>

<style scoped lang="scss">
.header {
  background-color: #001F3F;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5em;
  padding: 0 1em;

  &--links {
    
    &--wrapper {
      padding: .2em 0;
      margin-bottom: .4em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #ECF0F1;

      &--item {
        text-decoration: underline;
        cursor: pointer;
        font-size: 14px;

        &:hover {
          color: #ecf0f1c5;
        }
      }
    }

    &--search {
      width: 12em;
      height: 2.5em;
      display: flex;
      align-items: center;

      input {
        width: 100%;
        padding: .6em;
        font-size: 14px;
        border-radius: 5px;
        background-color: #001F3F;
        border: 1px solid #3498db;
        color: #ECF0F1;
      }
    }
  }

  h1 {
    color: #ECF0F1;
  }

  .modal--header,
  .modal--body {
    padding-bottom: 1em;
  }
}

@media (max-width: 550px) {
  .header {

    h1 {
      font-size: 24px;
    }

    &--links--search {
      width: 10em;
      height: 2em;

      input {
        padding: .4em;
      }
    }
  }
}
</style>
