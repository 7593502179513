<template>
  <div class="content">
    <GameList
      :siteData="siteData"
      @loadMore="loadMoreItems"
    />
    <ReturnToTop />
  </div>
</template>

<script>
import GameList from '@/components/GameList.vue';
import ReturnToTop from '@/components/ReturnToTop.vue';

export default {
  name: 'HomePage',
  props: {
    siteData: Array,
    rankData: Array,
    sortState: String,
    filterState: String,
    filterOriginState: String,
    cutReviews: [Number, String]
  },
  components: {
    GameList,
    ReturnToTop
  },
  methods: {
    loadMoreItems() {
      this.$emit('loadMore');

      return;
    },
  }
};
</script>

<style scoped lang="scss">
.content {
  display: flex;
  padding-bottom: 2rem;
  position: relative;
}
</style>
